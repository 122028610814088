import React from 'react'
import {
  ChipField,
  Datagrid,
  DateField,
  List,
  ReferenceArrayField,
  SelectField,
  SingleFieldList,
  TextField
} from 'react-admin'

export const UserList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="firstname" label="Vorname" />
      <TextField source="lastname" label="Nachname" />
      <SelectField
        label="Geschlecht"
        source="gender"
        choices={[
          { id: 'male', name: 'Mann' },
          { id: 'female', name: 'Frau' },
          { id: null, name: 'Keine Angaben' }
        ]}
      />
      <DateField source="birthdate" label="Geburtsdatum" />
      <ReferenceArrayField
        label="Favoriten"
        reference="locations"
        source="favorites">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
)
