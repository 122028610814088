import { ImageField, ImageInput } from 'react-admin'

const ImageInputer = ({ fieldName = 'imageUrls' }) => {
  return (
    <ImageInput
      source={fieldName}
      label="Bild"
      accept="image/*"
      multiple
      sx={{
        '& .previews': {
          display: 'flex',
          flexWrap: 'wrap'
        }
      }}>
      <ImageField
        source="src"
        title="title"
        sx={{
          '& .RaImageField-image': {
            minWidth: '50%',
            minHeight: 300
          }
        }}
      />
    </ImageInput>
  )
}

export default ImageInputer
