import {
  FirebaseAuthProvider,
  FirebaseDataProvider
} from 'react-admin-firebase'
import { getLocationsFromCloudfunction } from './functionGetListQuery'
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SERNDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

// All options are optional
const options = {
  persistence: 'local',
  dontAddIdFieldToDoc: false,
  lazyLoading: {
    enabled: false
  },
  firestoreCostsLogger: {
    enabled: false
  }
}
const defaultAuthProvider = FirebaseAuthProvider(config, options)

const permissionAuthProvider = {
  ...defaultAuthProvider,
  login: async params => {
    const user = await defaultAuthProvider.login(params)
    // @ts-ignore
    const claims = await defaultAuthProvider.getPermissions()
    const isAdmin = claims.admin
    if (isAdmin) {
      return user
    }
    // @ts-ignore
    await defaultAuthProvider.logout()
    throw new Error('Login error, invalid permissions')
  }
}
const defaultDataProvider = FirebaseDataProvider(config, options)

export const dataProvider = {
  ...defaultDataProvider,
  getList: async (resource, params) => {
    if (resource === 'locations') {
      return getLocationsFromCloudfunction(params)
    }
    return defaultDataProvider.getList(resource, params)
  }
}

export const authProvider = permissionAuthProvider
