import { Box, Typography } from '@mui/material'
import { Location } from '@nspire/interfaces/Location'
import imageCompression from 'browser-image-compression'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  FunctionField,
  List,
  NumberInput,
  ReferenceArrayInput,
  SearchInput,
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin'
import ImageInputer from '../components/ImageInput'
import ImageSorter from '../components/ImageSorter'
import { imageCompressionOptions } from '../statics'

const transform = async (data: Location) => {
  const { imageUrls } = data

  const transformedImageUrls = imageUrls
    ? await Promise.all(
        (imageUrls || []).map(async (url: any) => {
          if (url?.rawFile) {
            const compressedFile = await imageCompression(
              url.rawFile,
              imageCompressionOptions
            )
            return { ...url, rawFile: compressedFile }
          }
          return url
        })
      )
    : null
  const transformed = { ...data, imageUrls: transformedImageUrls }

  return transformed
}
const renderLocationGroups = (location: Location) =>
  location?.locationGroups?.join(', ') || ''
export const LocationList = props => {
  return (
    <List
      {...props}
      filters={[<SearchInput source="name" alwaysOn />]}
      debounce={0}>
      <Datagrid rowClick="edit" optimized>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" />
        <TextField source="zipcode" label="PLZ" />
        <TextField source="city" label="Stadt" />

        <FunctionField
          source="locationGroups"
          label="LocationGruppen"
          render={renderLocationGroups}
          sortBy="locationGroups[0]"
        />

        <BooleanField source="isVerified" />
        <BooleanField source="isPremium" />
        <BooleanField source="isActive" />
        <BooleanField source="isBlocked" />
      </Datagrid>
    </List>
  )
}

const LocationForm = props => {
  return (
    <SimpleForm {...props}>
      <Box p="1em">
        <Box display="flex">
          <Box flex={2} mr="1em">
            <Typography variant="h6" gutterBottom>
              Basics
            </Typography>
            <Box display="flex">
              <Box flex={1}>
                <TextInput source="name" label="Name" fullWidth />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1}>
                <TextInput
                  source="description"
                  label="Beschreibung"
                  fullWidth
                  multiline
                />
              </Box>
            </Box>

            <Box display="flex">
              <Box flex={1}>
                <ReferenceArrayInput
                  label="LocationGruppe"
                  source="locationGroups"
                  reference="locationGroups"
                  perPage={99999}
                  sort={{ field: 'name', order: 'ASC' }}
                  fullWidth>
                  <SelectArrayInput optionText="name" fullWidth />
                </ReferenceArrayInput>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="h6" gutterBottom>
                Öffnungszeiten
              </Typography>
              {[
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday'
              ].map((day, index) => (
                <>
                  <Typography gutterBottom>
                    {
                      [
                        'Montag',
                        'Dienstag',
                        'Mittwoch',
                        'Donnerstag',
                        'Freitag',
                        'Samstag',
                        'Sonntag'
                      ][index]
                    }
                  </Typography>
                  <Box flex={1} display="flex" flexDirection="row">
                    <NumberInput
                      source={`openingHours.${day}.fromHours`}
                      label="Von Zeit (Stunden)"
                      min={0}
                      max={23}
                    />
                    <NumberInput
                      source={`openingHours.${day}.fromMinutes`}
                      label="Von Zeit (Minuten)"
                      min={0}
                      max={59}
                    />
                    <Box pt={1.75} marginX={2} fontSize="30px">
                      {' - '}
                    </Box>
                    <NumberInput
                      source={`openingHours.${day}.toHours`}
                      label="Bis Zeit (Stunden)"
                      min={0}
                      max={23}
                    />
                    <NumberInput
                      source={`openingHours.${day}.toMinutes`}
                      label="Bis Zeit (Minuten)"
                      min={0}
                      max={59}
                    />
                  </Box>
                </>
              ))}
            </Box>
            <Typography variant="h6" gutterBottom>
              Kontakt
            </Typography>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput
                  source="contact.firstname"
                  label="Vorname"
                  fullWidth
                />
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInput
                  source="contact.lastname"
                  label="Nachname"
                  fullWidth
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput source="contact.email" label="Email" fullWidth />
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInput
                  source="contact.homepageUrl"
                  label="Homepage"
                  fullWidth
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput
                  source="contact.phoneNumber"
                  label="Telefonnummer"
                  fullWidth
                />
              </Box>

              <Box flex={1} ml="0.5em">
                <TextInput
                  source="contact.mobileNumber"
                  label="Handynummer"
                  fullWidth
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1}>
                <TextInput
                  source="contact.position"
                  label="Position"
                  fullWidth
                />
              </Box>
            </Box>
            <Typography variant="h6" gutterBottom>
              Adresse
            </Typography>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput source="address.street" label="Straße" fullWidth />
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInput
                  source="address.streetNr"
                  label="Hausnummer"
                  fullWidth
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput
                  source="address.streetAdditional"
                  label="Zusatz"
                  fullWidth
                />
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInput source="address.zipcode" label="PLZ" fullWidth />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput source="address.city" label="Stadt" fullWidth />
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInput source="address.country" label="Land" fullWidth />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <NumberInput source="address.latitude" label="Lat" fullWidth />
              </Box>
              <Box flex={1} ml="0.5em">
                <NumberInput
                  source="address.longitude"
                  label="Long"
                  fullWidth
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1}>
                <TextInput
                  source="address.googlePlacesID"
                  label="Google Places ID"
                  fullWidth
                />
              </Box>
            </Box>
            <Typography variant="h6" gutterBottom>
              Preis
            </Typography>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput source="price" fullWidth label="Preis" />
              </Box>
            </Box>
            <Typography variant="h6" gutterBottom>
              Toggles
            </Typography>
            <BooleanInput source="isPremium" label="Premium" />
            <BooleanInput source="isVerified" label="Verifiziert" />
            <BooleanInput source="isBlocked" label="Blockiert" />
            <BooleanInput source="isActive" label="Aktiv" />
            <Typography variant="h6" gutterBottom>
              Bilder
            </Typography>

            <ImageInputer />

            <ImageSorter />
          </Box>
        </Box>
      </Box>
    </SimpleForm>
  )
}
export const LocationEdit = props => {
  return (
    <Edit {...props} transform={transform}>
      <LocationForm {...props} />
    </Edit>
  )
}
export const LocationCreate = props => (
  <Create {...props} transform={transform}>
    <LocationForm {...props} />
  </Create>
)
