import { Box } from '@mui/material'

import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  TextField
} from 'react-admin'

const ImageSorter = ({ fieldName = 'imageUrls' }) => {
  return (
    <Box paddingY={'10px'} display="flex">
      <FormDataConsumer>
        {({ formData }) =>
          formData?.[fieldName]?.length > 1 && (
            <ArrayInput source={fieldName} label="Bilder Sortierung">
              <SimpleFormIterator disableAdd disableRemove>
                <FormDataConsumer>
                  {({ scopedFormData }) => (
                    <Box
                      flex={1}
                      display="flex"
                      justifyContent={'center'}
                      alignItems="center">
                      <TextField source="title" record={scopedFormData} />
                    </Box>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          )
        }
      </FormDataConsumer>
    </Box>
  )
}

export default ImageSorter
