import { Box } from '@mui/material'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  ImageField,
  ImageInput,
  List,
  ReferenceArrayInput,
  SearchInput,
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin'

export const ActivityList = props => (
  <List {...props} filters={[<SearchInput source="name" alwaysOn />]}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Name" />
      <BooleanField source="isActive" label="Aktiv" />
    </Datagrid>
  </List>
)
const ActivityForm = props => (
  <SimpleForm {...props}>
    <Box p="1em">
      <Box flex={1}>
        <Box flex={1}>
          <TextInput source="name" label="Name" fullWidth />
        </Box>
        <Box flex={1}>
          <BooleanInput source="isActive" label="Aktiv" fullWidth />
        </Box>
        <Box display="flex" flex={1}>
          <ReferenceArrayInput
            label="LocationGruppen"
            source="locationGroups"
            reference="locationGroups"
            perPage={99999}
            sort={{ field: 'name', order: 'ASC' }}
            fullWidth>
            <SelectArrayInput optionText="name" fullWidth />
          </ReferenceArrayInput>
        </Box>
      </Box>
      <Box flex={1}>
        <ImageInput source="image" label="Bild" accept="image/*">
          <ImageField
            source="src"
            title="title"
            sx={{
              '& .RaImageField-image': {
                maxWidth: '50%'
              }
            }}
          />
        </ImageInput>
      </Box>
    </Box>
  </SimpleForm>
)
export const ActivityEdit = props => (
  <Edit {...props}>
    <ActivityForm {...props} />
  </Edit>
)
export const ActivityCreate = props => (
  <Create {...props}>
    <ActivityForm {...props} />
  </Create>
)
