import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import orderBy from 'lodash/orderBy'
const cache = setupCache({
  maxAge: 2 * 60 * 1000
})
const api = axios.create({
  adapter: cache.adapter,
  baseURL: process.env.REACT_APP_CLOUDFUNCTION_BASE
})
const searchField = (object, field, search) => {
  if (!object[field]) return false
  return `${object[field]}`.toLowerCase().includes(`${search}`.toLowerCase())
}

export const getLocationsFromCloudfunction = async params => {
  const locationsJson = await api.get('/api-locationslist')
  const locations = Object.values(locationsJson.data)

  const filters = Object.keys(params.filter || {})
  const filtered =
    filters.length > 0
      ? locations.filter(location =>
          filters.every(filter =>
            searchField(location, filter, params.filter[filter])
          )
        )
      : locations

  const sorted = params.sort.field
    ? orderBy(
        filtered,
        params.sort.field,
        params.sort.order === 'ASC' ? 'asc' : 'desc'
      )
    : filtered

  const paginated = sorted.slice(
    (params.pagination.page - 1) * params.pagination.perPage,
    (params.pagination.page - 1) * params.pagination.perPage +
      params.pagination.perPage
  )

  return {
    data: paginated,
    total: sorted.length
  }
}
