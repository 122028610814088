import { Box, Typography } from '@mui/material'
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateTimeInput,
  Edit,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput
} from 'react-admin'
import ImageInputer from '../components/ImageInput'
import ImageSorter from '../components/ImageSorter'

export const EventsList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField label="Location" source="location" reference="locations">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Name" />
      <TextField source="description" label="Beschreibung" />

      <BooleanField source="isActive" label="Aktiv" />
    </Datagrid>
  </List>
)
const EventsForm = props => (
  <SimpleForm {...props}>
    <Box p="1em">
      <Box flex={1}>
        <Box flex={1}>
          <TextInput source="name" label="Name" fullWidth />
        </Box>
        <Box flex={1}>
          <TextInput
            source="description"
            label="Beschreibung"
            fullWidth
            multiline
          />
        </Box>
        <Box flex={1}>
          <BooleanInput source="isActive" label="Aktiv" fullWidth />
        </Box>
        <Box flex={1}>
          <NumberInput source="price" label="Preis" fullWidth />
        </Box>
        <Box flex={1}>
          <DateTimeInput source="startDate" label="Startdatum" fullWidth />
        </Box>
        <Box flex={1}>
          <DateTimeInput source="endDate" label="Enddatum" fullWidth />
        </Box>
        <Box flex={1}>
          <ReferenceInput
            filter={{ isPremium: true }}
            label="Location"
            source="location"
            reference="locations"
            perPage={99999}
            sort={{ field: 'name', order: 'ASC' }}
            fullWidth>
            <SelectInput optionText="name" fullWidth />
          </ReferenceInput>
        </Box>
        <Box flex={1}>
          <SelectInput
            label="Event-Typ"
            source="eventType"
            fullWidth
            choices={[
              { id: 'regular', name: 'Normal' },
              { id: 'special', name: 'Spezial' }
            ]}
          />
        </Box>
        <Box flex={1}>
          <ArrayInput source="affiliateLinks">
            <SimpleFormIterator>
              <TextInput fullWidth source="title" label="Titel" />
              <TextInput fullWidth source="data" label="Data" multiline />
              <SelectInput
                fullWidth
                label="Typ"
                source="type"
                choices={[
                  { id: 'html', name: 'HTML' },
                  { id: 'link', name: 'Link' }
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
        <Box flex={1} mb={5} mt={2}>
          <Typography>Tags</Typography>
          <ArrayInput source="tags">
            <SimpleFormIterator>
              <TextInput source="" label="tag" />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>

        <Box flex={1} display="flex">
          <ImageInputer fieldName="imageUrls" />
        </Box>
        <ImageSorter fieldName="imageUrls" />
      </Box>
    </Box>
  </SimpleForm>
)
export const EventsEdit = props => (
  <Edit {...props}>
    <EventsForm {...props} />
  </Edit>
)
export const EventsCreate = props => (
  <Create {...props}>
    <EventsForm {...props} />
  </Create>
)
