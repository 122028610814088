import { Box } from '@mui/material'
import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceArrayInput,
  SearchInput,
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin'
export const ActivityGroupList = props => (
  <List {...props} filters={[<SearchInput source="name" alwaysOn />]}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Name" />
      <TextField source="goodToKnow" label="Gut zu wissen" />

      <TextField source="category.name" label="Kategorie" />
    </Datagrid>
  </List>
)
const ActivityGroupForm = props => (
  <SimpleForm {...props}>
    <Box width={'100%'}>
      <Box display="flex" flex={1}>
        <Box display="flex" flex={1}>
          <TextInput source="name" label="Name" fullWidth />
        </Box>
      </Box>
      <Box display="flex" flex={1}>
        <Box flex={1}>
          <TextInput
            source="goodToKnow"
            label="Gut zu wissen"
            fullWidth
            multiline
          />
        </Box>
      </Box>

      <Box display="flex" flex={1}>
        <ReferenceArrayInput
          label="Aktivitäten"
          source="activities"
          reference="activities"
          fullWidth>
          <SelectArrayInput optionText="name" fullWidth />
        </ReferenceArrayInput>
      </Box>
    </Box>
  </SimpleForm>
)
export const ActivityGroupEdit = props => (
  <Edit {...props}>
    <ActivityGroupForm {...props} />
  </Edit>
)
export const ActivityGroupCreate = props => (
  <Create {...props}>
    <ActivityGroupForm {...props} />
  </Create>
)
