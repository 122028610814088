import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import EventIcon from '@mui/icons-material/Event'
import PersonIcon from '@mui/icons-material/Person'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import { createTheme } from '@mui/material/styles'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import germanMessages from 'ra-language-german'
import React from 'react'
import { Admin, Resource, Login, defaultTheme } from 'react-admin'
import { authProvider, dataProvider } from './initFirebase'
import {
  ActivityCreate,
  ActivityEdit,
  ActivityList
} from './resources/Activities'
import {
  LocationGroupCreate,
  LocationGroupEdit,
  LocationGroupList
} from './resources/LocationGroup'
import {
  LocationCreate,
  LocationEdit,
  LocationList
} from './resources/Locations'
import { UserList } from './resources/Users'
import { EventsCreate, EventsEdit, EventsList } from './resources/Events'
import {
  ActivityGroupCreate,
  ActivityGroupEdit,
  ActivityGroupList
} from './resources/ActivityGroup'

const theme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#3f51b5'
    },
    secondary: {
      main: '#4c94f6'
    }
  }
})

const messages = {
  de: germanMessages
}
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'de')

function App() {
  return (
    <Admin
      // eslint-disable-next-line react/no-unstable-nested-components
      loginPage={() => <Login className="loginPage" />}
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      // @ts-ignore
      i18nProvider={i18nProvider}>
      <Resource
        name="users"
        list={UserList}
        icon={PersonIcon}
        options={{ label: 'Benutzer' }}
      />
      <Resource
        name="activityGroups"
        list={ActivityGroupList}
        edit={ActivityGroupEdit}
        create={ActivityGroupCreate}
        icon={WorkspacesIcon}
        options={{ label: 'AktivitätenGruppen' }}
      />
      <Resource
        name="activities"
        list={ActivityList}
        edit={ActivityEdit}
        create={ActivityCreate}
        icon={LocalActivityIcon}
        options={{ label: 'Aktivitäten' }}
      />
      <Resource
        name="locationGroups"
        list={LocationGroupList}
        edit={LocationGroupEdit}
        create={LocationGroupCreate}
        icon={AddLocationAltIcon}
        options={{ label: 'LocationGruppen' }}
      />
      <Resource
        name="locations"
        list={LocationList}
        edit={LocationEdit}
        create={LocationCreate}
        icon={LocationOnIcon}
        options={{ label: 'Locations' }}
      />

      <Resource
        name="events"
        list={EventsList}
        edit={EventsEdit}
        create={EventsCreate}
        icon={EventIcon}
        options={{ label: 'Events' }}
      />
    </Admin>
  )
}

export default App
