import { Box } from '@mui/material'
import { LocationGroup } from '@nspire/interfaces/Location'
import imageCompression from 'browser-image-compression'
import {
  ArrayInput,
  Create,
  Datagrid,
  Edit,
  BooleanInput,
  FunctionField,
  List,
  Pagination,
  SelectArrayInput,
  SelectField,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput
} from 'react-admin'
import ImageInputer from '../components/ImageInput'
import ImageSorter from '../components/ImageSorter'
import { imageCompressionOptions } from '../statics'

const transform = async (data: LocationGroup) => {
  const { images } = data

  const transformedImageUrls = images
    ? await Promise.all(
        images?.map(async (url: any) => {
          if (url?.rawFile) {
            const compressedFile = await imageCompression(
              url.rawFile,
              imageCompressionOptions
            )
            return { ...url, rawFile: compressedFile }
          }
          return url
        })
      )
    : null
  const transformed = { ...data, images: transformedImageUrls }

  return transformed
}
export const LocationGroupList = props => (
  <List
    {...props}
    filters={[
      <TextInput source="name" label="Name" alwaysOn />,
      <SelectInput
        label="Preismodell"
        source="priceModel"
        fullWidth
        choices={[
          { id: 'free', name: 'Kostenlos' },
          { id: 'paid', name: 'Kostenpflichtig' }
        ]}
      />,
      <SelectArrayInput
        fullWidth
        label="Zielgruppen"
        source="targetGroups"
        choices={[
          { id: 'single', name: 'Singles' },
          { id: 'couple', name: 'Pärchen' },
          { id: 'family', name: 'Familien' },
          { id: 'friends', name: 'Freunde' }
        ]}
      />,
      <SelectArrayInput
        label="ActionLevel"
        source="actionLevels"
        fullWidth
        choices={[
          { id: '1', name: '1' },
          { id: '2', name: '2' },
          { id: '3', name: '3' },
          { id: '4', name: '4' },
          { id: '5', name: '5' }
        ]}
      />,
      <SelectArrayInput
        label="Kategorie"
        source="categories"
        fullWidth
        choices={[
          { id: 'foodAndDrink', name: 'Essen & Trinken' },
          { id: 'party', name: 'Party & Feiern' },
          { id: 'event', name: 'Feste & Veranstaltungen' },
          { id: 'park', name: 'Parks & Natur' },
          { id: 'game', name: 'Spiele' },
          { id: 'sport', name: 'Sport' },
          { id: 'travel', name: 'Reisen' },
          { id: 'beauty', name: 'Wellness & Beauty' },
          { id: 'culture', name: 'Kultur' },
          { id: 'karma', name: 'Karma aufbessern' }
        ]}
      />
    ]}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} />}
    perPage={50}>
    <Datagrid rowClick="edit" optimized>
      <TextField source="name" label="Name" />
      <TextField source="goodToKnow" label="Gut zu wissen" />
      <FunctionField
        label="Kategorien"
        source="categories"
        sortBy="categories[0]"
        render={(record: LocationGroup) => record?.categories?.join(', ')}
      />
      <FunctionField
        label="Zielgruppen"
        source="targetGroups"
        sortBy="targetGroups[0]"
        render={(record: LocationGroup) => record?.targetGroups?.join(', ')}
      />
      <FunctionField
        label="Actionlevel"
        source="actionLevels"
        sortBy="actionLevels[0]"
        render={(record: LocationGroup) => record?.actionLevels?.join(', ')}
      />
      <FunctionField
        label="AffiliateLinks"
        source="affiliateLinks"
        sortBy="affiliateLinks[0]"
        render={(record: LocationGroup) => record?.affiliateLinks?.length || 0}
      />
      <SelectField
        label="Preismodell"
        source="priceModel"
        choices={[
          { id: 'free', name: 'Kostenlos' },
          { id: 'paid', name: 'Kostenpflichtig' },
          { id: null, name: 'Keine Angaben' }
        ]}
      />
      <TextField source="locationCount" label="Anzahl Locations" />
    </Datagrid>
  </List>
)
const LocationGroupForm = props => (
  <SimpleForm {...props}>
    <Box p="1em">
      <Box display="flex" flex={1}>
        <Box display="flex" flex={1}>
          <TextInput source="name" label="Name" fullWidth />
        </Box>
      </Box>
      <Box display="flex" flex={1}>
        <Box flex={1}>
          <TextInput
            source="goodToKnow"
            label="Gut zu wissen"
            fullWidth
            multiline
          />
        </Box>
      </Box>
      <Box display="flex" flex={1}>
        <Box display="flex" flex={1}>
          <SelectArrayInput
            label="Kategorie"
            source="categories"
            fullWidth
            choices={[
              { id: 'foodAndDrink', name: 'Essen & Trinken' },
              { id: 'party', name: 'Party & Feiern' },
              { id: 'event', name: 'Feste & Veranstaltungen' },
              { id: 'park', name: 'Parks & Natur' },
              { id: 'game', name: 'Spiele' },
              { id: 'sport', name: 'Sport' },
              { id: 'travel', name: 'Reisen' },
              { id: 'beauty', name: 'Wellness & Beauty' },
              { id: 'culture', name: 'Kultur' },
              { id: 'karma', name: 'Karma aufbessern' }
            ]}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <SelectArrayInput
            fullWidth
            label="Location-Typ"
            source="locationTypes"
            choices={[
              { id: 'online', name: 'Online' },
              { id: 'indoor', name: 'Indoor' },
              { id: 'outdoor', name: 'Outdoor' }
            ]}
          />
        </Box>
        <Box flex={1} ml="0.5em">
          <SelectArrayInput
            label="ActionLevel"
            source="actionLevels"
            fullWidth
            choices={[
              { id: 'easy', name: 'Locker' },
              { id: 'normal', name: 'Normal' },
              { id: 'active', name: 'Aktiv' }
            ]}
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1}>
          <SelectInput
            label="Preismodell"
            source="priceModel"
            fullWidth
            choices={[
              { id: 'free', name: 'Kostenlos' },
              { id: 'paid', name: 'Kostenpflichtig' }
            ]}
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mb="1em">
          <ArrayInput source="affiliateLinks">
            <SimpleFormIterator>
              <TextInput fullWidth source="title" label="Titel" />
              <TextInput fullWidth source="data" label="Data" multiline />
              <SelectInput
                fullWidth
                label="Typ"
                source="type"
                choices={[
                  { id: 'html', name: 'HTML' },
                  { id: 'link', name: 'Link' }
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <SelectArrayInput
            fullWidth
            label="Zielgruppen"
            source="targetGroups"
            choices={[
              { id: 'single', name: 'Singles' },
              { id: 'couple', name: 'Pärchen' },
              { id: 'family', name: 'Familien' },
              { id: 'friends', name: 'Freunde' }
            ]}
          />
        </Box>
        <Box flex={1} ml="0.5em">
          <SelectArrayInput
            fullWidth
            label="Saisons"
            source="seasons"
            choices={[
              { id: 'spring', name: 'Frühling' },
              { id: 'summer', name: 'Sommer' },
              { id: 'autumn', name: 'Herbst' },
              { id: 'winter', name: 'Winter' }
            ]}
          />
        </Box>
      </Box>

      <Box display="flex" flex={1}>
        <BooleanInput source="isLocationDependent" label="Ortsgebunden" />
      </Box>

      <Box display="flex" flex={1} flexWrap="wrap">
        <ImageInputer fieldName="images" />
      </Box>
      <ImageSorter fieldName="images" />
    </Box>
  </SimpleForm>
)
export const LocationGroupEdit = props => (
  <Edit {...props} transform={transform}>
    <LocationGroupForm {...props} />
  </Edit>
)
export const LocationGroupCreate = props => (
  <Create {...props} transform={transform}>
    <LocationGroupForm {...props} />
  </Create>
)
